<template>
  <div class="page">
    <div class="box">
        <div class="setting" onTap="handleTapGoPdf" data-pdf="5">
            <div class="setting-top">
                <div class="setting-span" @click="jump('https://pdf.dingxiangzu.com/MODEL/h5/PrivacyPolicy.pdf')" >
                    <span>隐私协议</span>
                </div>
                <div class="setting-more-span">
                    <div class="right-img"></div>
                </div>
            </div>
        </div>
        <div class="setting" onTap="handleTapGoPdf" data-pdf="4">
            <div class="setting-top" @click="jump('https://pdf.dingxiangzu.com/MODEL/h5/UserInformationAuthorizationLetter.pdf')">
                <div class="setting-span">
                    <span>用户协议</span>
                </div>
                <div class="setting-more-span">
                    <div class="right-img"></div>
                </div>
            </div>
        </div>
    </div>
    <a href="tel:" class="f28 cad link" onTap="link">联系我们：</a>
</div>
</template>
<script setup>
const jump = (url) => {
  window.open(url);
};
</script>
<style lang="scss" scoped>
.page {
  width: 750px;
  min-height: 1472px;
  height: 100%;
  background: #F2F2F2;
  box-sizing: border-box;
  /* padding:0px; */
  /* margin-top: 24px; */
  margin-top: 24px;
  padding-left: 0px;
  padding-right: 0;
}
.box{
  margin: 0 24px;
  padding: 0px 26px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 10px;
}

.setting {
  /* width: 750px; */
  /* height: 120px; */
  background: #FFFFFF;
  box-sizing: border-box;
}

.setting:first-child {
  border-bottom: 1px solid rgba(229, 229, 229, 1);
}

.setting-top {
  padding: 32px 0 28px;
  display: flex;
  background: #FFFFFF;
  box-sizing: border-box;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
}

.setting-text {
  /* width: 128px; */
  height: 45px;
  /* line-height: 40px; */
}

.setting-text text {
  font-size: 28px;
  font-weight: 400;
  color: #121418;
  line-height: 40px;
}

.setting-more-text .right-img {
  width: 24px;
  height: 48px;
  background: url("https://file.dingxiangzu.com/alipay/public/right.png") no-repeat;
  background-size: 100%;
}

.link {
  position: absolute;
  width: 100%;
  bottom: 70px;
  text-align: center
}
</style>
